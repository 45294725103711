import { useState } from "react";
import "./ExpenseForm.css";

const ExpenseForm = (props) => {
  const [enteredTitle, setEnteredTitle] = useState("");
  const [enteredCost, setEnteredCost] = useState("");
  const [enteredDate, setEnteredDate] = useState("");

  const titleChangeHandler = (evt) => {
    console.log(evt.target.value);
    setEnteredTitle(evt.target.value);
  };

  const costChangeHandler = (evt) => {
    console.log(evt.target.value);
    setEnteredCost(evt.target.value);
  };

  const dateChangeHandler = (evt) => {
    console.log(evt.target.value);
    setEnteredDate(evt.target.value);
  };

  const formSubmitHandler = (evt) => {
    evt.preventDefault();
    const formData = {
      title: enteredTitle,
      amount: enteredCost,
      date: new Date(enteredDate),
    };

    // console.log(formData);

    props.onSaveExpenseData(formData);
    setEnteredTitle("");
    setEnteredCost("");
    setEnteredDate("");
  };

  return (
    <form className="new-expense__form" onSubmit={formSubmitHandler}>
      <div className="new-expense__controls">
        <div className="new-expense__control">
          <label>Title</label>
          <input
            type="text"
            value={enteredTitle}
            onChange={titleChangeHandler}
          />
        </div>
        <div className="new-expense__control">
          <label>Cost</label>
          <input
            type="number"
            min="0.01"
            step="0.01"
            value={enteredCost}
            onChange={costChangeHandler}
          />
        </div>
        <div className="new-expense__control">
          <label>Date</label>
          <input type="date" value={enteredDate} onChange={dateChangeHandler} />
        </div>
      </div>
      <div className="new-expense__actions">
        <button>Add expense</button>
      </div>
    </form>
  );
};

export default ExpenseForm;
