import { useState } from "react";
import "./App.css";
import Expenses from "./components/expenses/Expenses";
import NewExpense from "./components/newExpense/NewExpense";
import ExpenseData from './resources/ExpenseData';

function App() {
  const [expenses, setExpenses] = useState(ExpenseData);

  const onNewExpenseHandler = (newExpenseData) => {
    console.log('app: ', newExpenseData);
    setExpenses(prevData => [newExpenseData, ...prevData]);
  }

  return (
    <div className="App">
      Hello
      <NewExpense onNewExpense={onNewExpenseHandler} />
      <Expenses expenses={expenses} />
    </div>
  );
}

export default App;
