// import styles from './ExpenseItem.css';
import { useState } from 'react';
import Card from '../ui/Card';
import './ExpenseItem.css'
import ExpenseDate from './ExpenseDate';
import React from 'react';

const ExpenseItem = (props) => {
  const [title, setTitle] = useState(props.title);

  const clickHandler = (evt) => {
    setTitle('updated')
  }
  return (
    <li>
      <Card className="expense-item">
        <ExpenseDate date={props.date} />
        <div className="expense-item__description">
          <h2>{ title }</h2>
          <div className="expense-item__price">Amount: { props.amount }</div>
        </div>
        <button onClick={clickHandler}>Change title</button>
      </Card>
    </li>
  );
};

export default ExpenseItem;
